<template>
	<div>
		<div class="swperList">
			<div style="text-align: center;">
				<!-- <div class="qa_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">QUALITY</div> -->
				<div class="sl_title">{{$t('bomSupply.swperList.title')}}</div>
				<div class="sl_titleText">
					<div>{{$t('bomSupply.swperList.text1')}}</div>
					<div>{{$t('bomSupply.swperList.text2')}}</div>
					<div>{{$t('bomSupply.swperList.text3')}}</div>
				</div>
			</div>

			<div class="sl_content">
				<div class="sl_ct_div">
					<div class="sl_ct_div_imageDiv" :data-wow-duration="item.time" :data-wow-delay="item.time"
						data-wow-offset="0" v-for="(item,index) in $t('bomSupply.swperList.list')" :key="index">
						<div style="display: flex;justify-content: center;">
							<img style="width: 50px;height: 50px;margin-top: 20px;" :src="item.image" />
						</div>
						<div class="sl_text">
							<div style="position: relative;width: 100%;height: 100%;">
								<div class="sl_text_div"
									style="position: relative;width: 100%;height: 100%;text-align: center;">
									<div class="sl_text_div_title">{{item.title}}</div>
									<div class="sl_text_div_title2">{{item.text}}</div>
									<div class="sl_text_div_content">{{item.content}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list: [{
						title: "“一站式”配单服务",
						text: "支持BOM整单“ 一站式”采购服务，解决您的多点购货烦恼",
						image: require('@/assets/img/view-icon1.png'),
						content: "将复杂的询价处理环节变繁为简，如同为您配备专业的采购团队； 物料配套率可实现98%，降低询价成本； 每天24小时不间断运作，保证需求反馈效率。"
					},
					{
						title: "产品配齐率高",
						text: "支持紧缺、停产、断档、冷偏门、受控元器件供应服务，解决您的缺料苦恼",
						image: require('@/assets/img/view-icon2.png'),
						content: "几乎没有起订量和起订金额限制； 有效提供研发阶段的成本控制预算； 协助调查后续量产中各项物料市场供应情况，为稳定生产提供保障。"
					},
					{
						title: "多种类解决方案",
						text: "支持剩余电子物料代销、清仓服务，助您减少资产损失",
						image: require('@/assets/img/view-icon3.png'),
						content: "大量潜在客户需求，提高呆料变现率，盘活企业周转资金； 快速、简单、高比率解决剩余库存压力； 降低库存管理人工费用和仓储空间，控制多次盘点丢失风险。"
					},
					{
						title: "售前与售后",
						text: "支持售前、售后增值性服务， 充当您工作中解决各类棘手问题的贴身顾问",
						image: require('@/assets/img/view-icon4.png'),
						content: "提供技术支持，查找文档datasheet和资料； 帮助推荐寻找停产、断档器件的替代方案； 酌情提供样品。"
					},
					{
						title: "物料代销",
						text: "支持剩余电子物料代销、清仓服务，助您减少资产损失",
						image: require('@/assets/img/view-icon5.png'),
						content: "大量潜在客户需求，提高呆料变现率，盘活企业周转资金； 快速、简单、高比率解决剩余库存压力； 降低库存管理人工费用和仓储空间，控制多次盘点丢失风险。"
					}
				]
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 200px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 200px 60px 200px;
		
			.sl_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -200px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 10px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 10px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
	@media only screen and (max-width:1920px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 200px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 200px 60px 200px;
		
			.sl_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -200px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 10px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 10px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 140px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 140px 60px 140px;
		
			.sl_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -180px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 10px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 10px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 200px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 30px 60px 30px;
		
			.sl_title {
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -180px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 10px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 10px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 200px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 30px 60px 30px;
		
			.sl_title {
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(94% / 2);
			height: 400px;
			position: relative;
			margin: 10px;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -180px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 10px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 10px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
	@media only screen and (max-width:767px) {
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 200px;
			margin: 0;
		}
		
		.swperList {
			position: relative;
			width: 100%;
			padding: 90px 30px 60px 30px;
		
			.sl_title {
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
		
			.sl_titleText {
				font-size: 16px;
				color: #999999;
				text-align: center;
				margin-top: 8px;
				line-height: 1.7;
			}
		
			.sl_content {
				overflow: visible;
				margin-top: 40px;
		
				.sl_ct_div {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
				}
			}
		}
		
		.sl_ct_div_imageDiv {
			background: #d1102d;
			width: calc(80% / 1);
			height: 400px;
			position: relative;
			margin: 10px;
			overflow: hidden;
			cursor: pointer;
		}
		
		.sl_ct_div_imageDiv .sl_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.sl_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.sl_mask2 {
			z-index: 4;
			// background: linear-gradient(to left, #ff6724, red);
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask2 {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.sl_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.sl_text_div {
			position: absolute;
			bottom: -180px;
			transition: all 1s;
		}
		
		.sl_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.sl_text_div_title2 {
			width: 100%;
			font-size: 16px;
			padding-top: 10px;
			height: 60px;
		}
		
		.sl_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 16px;
			line-height: 25px;
			height: 180px;
			color: #fff;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div {
			bottom: 0;
		}
		
		.sl_ct_div_imageDiv:hover .sl_text_div_content {}
	}
	
</style>
