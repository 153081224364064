<template>
	<div>
		<div class="recommendation" v-if="windowWidth>=1200">
			<div class="rd_title">{{$t('bomSupply.recommendation.title')}}</div>
			
			<div class="rd_text1">
				{{$t('bomSupply.recommendation.text1')}}
			</div>
			<div class="rd_text2">
				{{$t('bomSupply.recommendation.text2')}}
			</div>
			
			<div class="rd_contentDiv">
				<div class="rd_cd_div">
					<div class="rd_cd_di_text">{{$t('bomSupply.recommendation.text3')}}</div>
					<el-image class="rd_cd_di_img" :src="$t('bomSupply.recommendation.img1')" :fit="'cover'"></el-image>
				</div>
				
				<div class="rd_cd_div">
					<div class="rd_cd_di_text">{{$t('bomSupply.recommendation.text4')}}</div>
					<el-image class="rd_cd_di_img" :src="$t('bomSupply.recommendation.img2')" :fit="'cover'"></el-image>
				</div>
			</div>
			
			<!-- <div style="padding-top: 40px;display: flex;">
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img3')" />
					<div style="color: #0b8cb8;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text5')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img4')" />
					<div style="color: #07b3c1;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text6')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img5')" />
					<div style="color: #2ac5f8;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text7')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img6')" />
					<div style="color: #f8a52a;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text8')}}</div>
				</div>
			</div> -->
		</div>
		
		<div class="recommendation2" v-if="windowWidth<=1199">
			<div class="rd_title">{{$t('bomSupply.recommendation.title')}}</div>
			
			<div class="rd_text1">
				{{$t('bomSupply.recommendation.text1')}}
			</div>
			<div class="rd_text2">
				{{$t('bomSupply.recommendation.text2')}}
			</div>
			
			<div class="rd_contentDiv">
				<div class="rd_cd_div">
					<div class="rd_cd_di_text">{{$t('bomSupply.recommendation.text3')}}</div>
					<el-image class="rd_cd_di_img" :src="$t('bomSupply.recommendation.img1')" :fit="'cover'"></el-image>
				</div>
				
				<div class="rd_cd_div">
					<div class="rd_cd_di_text">{{$t('bomSupply.recommendation.text4')}}</div>
					<el-image class="rd_cd_di_img" :src="$t('bomSupply.recommendation.img2')" :fit="'cover'"></el-image>
				</div>
			</div>
			
			<!-- <div style="padding-top: 40px;display: flex;">
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img3')" />
					<div style="color: #0b8cb8;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text5')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img4')" />
					<div style="color: #07b3c1;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text6')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img5')" />
					<div style="color: #2ac5f8;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text7')}}</div>
				</div>
				
				<div style="margin-right: 20px;">
					<img :src="$t('bomSupply.recommendation.img6')" />
					<div style="color: #f8a52a;font-size: 10px;text-align: center;">{{$t('bomSupply.recommendation.text8')}}</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.recommendation{
			padding: 40px 200px;
			
			.rd_title{
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				display: flex;
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 49%;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 700px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.recommendation{
			padding: 40px 200px;
			
			.rd_title{
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				display: flex;
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 49%;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 700px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.recommendation{
			padding: 40px 140px;
			
			.rd_title{
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				display: flex;
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 49%;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 700px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.recommendation{
			padding: 40px 30px;
			
			.rd_title{
				font-size: 34px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				display: flex;
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 49%;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 700px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.recommendation2{
			padding: 40px 30px;
			
			.rd_title{
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 100%;
					margin-bottom: 10px;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 50vw;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.recommendation2{
			padding: 40px 30px;
			
			.rd_title{
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
			
			.rd_text1{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_text2{
				font-size: 16px;
				color: #333333;
				line-height: 24px;
				margin-top: 22px;
				text-align: center;
			}
			
			.rd_contentDiv{
				width: 100%;
				margin-top: 20px;
				
				.rd_cd_div{
					width: 100%;
					margin-bottom: 10px;
					margin-right: 1%;
					position: relative;
					
					.rd_cd_di_text{
						position: absolute;
						width: 100%;
						padding: 20px;
						color: #fff;
						background: rgba(240, 56, 60, 0.9);
						bottom: 0;
						text-align: center;
						z-index: 99;
					}
					
					.rd_cd_di_img{
						width: 100%;
						height: 50vw;
					}
				}
			}
		}
	}
	
</style>